@import "@/assets/scss/style.scss";

.action-buttons {
    display: flex;

    .action-btn {
        margin: 0 5px;
        &:first-child {
            @media screen and (max-width: 450px) {
                margin-bottom: 5px;
            }
        }
    }
}

.home-top-bar {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 0 5%;

    @media screen and (max-width: 450px) {
        height: 80px;
        flex-direction: column;
    }
}

.left-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .product-info {
        padding: 0 1.5rem;

        .path-before {
            font-size: 0.96rem;
            font-weight: 400;
            color: $grey-7;
        }

        .path-current {
            font-size: 0.96rem;
            font-weight: 450;
            color: #262626;
        }

        .product-name {
            font-size: 1.125rem;
            font-weight: bold;
            color: $grey-9;
        }
    }
}
.misc {
    padding: 0 8px;
}

.icon-color {
    color: $grey-9;
}
