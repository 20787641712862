@import "@/assets/scss/style.scss";

.co-home {
    padding: 24px 24px;
    background-color: white;
    &__button-group {
        margin-bottom: 10px;
    }

    &__number-of-results {
        color: #8c8c8c;
    }
    .table-border {
        border-style: solid;
        border-width: 1px;
        border-color: $grey-4;
        border-radius: 10px;
        overflow: hidden;
    }
}
